import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import ConfigurationStore from "../../stores/ConfigurationStore";



function ProductCode(props) {
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const form = useRef(null);

  const [client_id, setClientId] = useState(null);
  const [syncToCrm, setSyncToCrm] = useState(false);


  useEffect(() => {
    ConfigurationStore.getClients();
    let id = props.match.params.id;
    if (id) {
      ConfigurationStore.getProductCode(id).then((result) => {
        Object.keys(result).map(key => {
          console.log(`result on productCode edit: ${JSON.stringify(result)}`)
          if (document.getElementsByName(key).length > 0) {
            document.getElementsByName(key)[0].value = result[key]
          }
          if(key === 'client_id'){
            setClientId(result[key])
         }
         if(key === 'syncToCrm'){
          document.getElementsByName(key)[0].checked = result['syncToCrm'];
          setSyncToCrm(result['syncToCrm']);
      }
        })
      })
      setEditMode(true);
    }

    
  }, [])

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(form.current);
    let data = {};
   // let userCompany = localStorage.getItem('userCompany')
    for (var key of formData.keys()) {
      data[key] = formData.get(key);
    }
    data['syncToCrm'] = syncToCrm;

   
    if (editMode) {
      let productCode_id = props.match.params.id;
      delete data.client;
      delete data.client_id;
      ConfigurationStore.updateProductCode(productCode_id, data)
        .then(() => {
          setLoading(false);
          props.history.push("/productcodes");
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    else {
      ConfigurationStore.createProductCode(data)
        .then(() => {
          setLoading(false);
          props.history.push("/productcodes");
        })
        .catch((err) => {
          setLoading(false);
        });
    }


  };

  let { clients } = ConfigurationStore;
  return (
    <div className="add-element">
      <div className="box">
        <h2>{editMode ? 'Edit' : 'Add'} Product Code</h2>
        <div className="form-block">
          <form ref={form} onSubmit={(e) => onSubmit(e)}>
            <div className="input-box">
              <label>Name</label>
              <input className="form-control" name="name" />
            </div>
            <div className="input-box">
              <label>Product Code</label>
              <input className="form-control" name="productCode" />
            </div>
            <div className="input-box">
              <label>Shopify Collection Name</label>
              <input className="form-control" name="shopifyCollection" />
            </div>
            <div className="input-box">
                                     <label>Sync to CRM</label>
                                     <input onChange={e=>setSyncToCrm(e.target.checked)} type="checkbox" name="syncToCrm" />
                                 </div>
           
              <div className="input-box">
                <label>Select Client</label>
                  <select
                    name="client_id"
                    className="form-control"
                    value={client_id}
                    disabled={editMode}
                  >
                <option value="">Select client</option>
                {clients &&
                  clients.map((c) => {
                  return (
                <option value={c._id}>
                {c.name}
                </option>
                );
                })}
                </select>
            </div>
            <div className="btn-box">
              <button disabled={loading} htmlType="submit" type="submit" className="primarybtn create-cnt-btn">
                {loading ? `Please wait...` : `${editMode ? 'Update' : 'Create'} Product Code`}
              </button>
            </div>
          </form>
        </div>

      </div>
    </div>
  );
}

export default observer(ProductCode);
