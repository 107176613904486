import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect } from 'react'
import ConfigurationStore from '../../stores/ConfigurationStore';
import GlobalStore from '../../stores/GlobalStore';
import {Link} from 'react-router-dom';

function SpireAccounts(props) {

    useEffect(()=>{
        ConfigurationStore.getSpireAccounts();
        GlobalStore.pageName = 'spire-accounts';
    },[])

    const edit = (id) => {
        props.history.push(`/spire-account/edit/${id}`);
    }

    const onDelete = (id) => {
       let r = window.confirm('Are you sure to delete this account?');
       if(r){
           ConfigurationStore.deleteSpireAccount(id).then(()=>{
                ConfigurationStore.getSpireAccounts()
           })
       }
    }

    let {spireAccounts=[]} = ConfigurationStore;
    return <React.Fragment>
        <div className="table-info">
            <table border="0" cellSpacing="0">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Endpoint</th>
                        <th>Username</th>
                        <th>Def Warehouse</th>
                        <th>Sales Code</th>
                        <th>Sales Name</th>
                        <th>In-use</th>
                        <th>Last used</th>
                        <th>Client</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {
                    spireAccounts && spireAccounts.map(account=>{
                        return <tr>
                                    <td>{account.name}</td>
                                    <td>{account.endpoint}</td>
                                    <td>{account.username}</td>
                                    <td>{account.warehouse}</td>
                                    <td>{account.salesperson_code}</td>
                                    <td>{account.salesperson_name}</td>
                                    <td>
                                        <span className={`color-text ${account.in_use?`gr-color`:`rd-color`}`}>{account.in_use?'YES':'NO'}</span>
                                    </td>
                                    <td>{moment(account.createdAt).format('DD/MM/YYYY hh:mm A')}</td>
                                    <td>{account?.client_data[0]?.code}</td>
                                    <td>
                                        <button className="primarybtn" onClick={()=>edit(account._id)}>Edit</button>
                                        <button disabled={account.in_use} onClick={()=>onDelete(account._id)} className="primarybtn delete-btn">Delete</button>
                                    </td>
                                </tr>
                    })
                }
                    
                </tbody>
            </table>
        </div>

        <div className="bottom-button">
            <Link to="/spire-account/add"><button className="primarybtn add-btn">+ Add Account</button></Link>
        </div>
    </React.Fragment>
}

export default observer(SpireAccounts);
