import { observer } from "mobx-react";
import { useEffect } from "react";
import ConfigurationStore from "../../stores/ConfigurationStore"
import GlobalStore from "../../stores/GlobalStore";
import {Link} from 'react-router-dom';

function ShopifyProductCategories(props){
    
    useEffect(()=>{
        ConfigurationStore.getShopifyProductCategories();
        GlobalStore.pageName = 'shopifyproductcategories';
    },[])



    const edit = (category_id) => {
        props.history.push(`/shopifyproductcategory/edit/${category_id}`);
    }

    let {shopifyProductCategories=[]} = ConfigurationStore;
    console.log(`warehouses: ${JSON.stringify(shopifyProductCategories)}`)
    return <div className="table-info">
                <table border="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th>Product Category</th>
                            <th>Category ID</th>
                            <th>Action</th>
                            
                        </tr>
                    </thead>
		    <tbody>
                    {
                        shopifyProductCategories && shopifyProductCategories.map(category=>{
                            return <tr>
                                        <td>{category.name}</td>
                                        <td>{category.productTaxonomyId}</td>
                                        
                                        <td>
                                        <button className="primarybtn" onClick={()=>edit(category._id)}>Edit</button>
                                        </td>
                                        
                                    </tr>
                        })
                    }
                        
                    </tbody>
                    
                </table>

                <div className="bottom-button">
                    <Link to="/shopifyproductcategory/add"><button className="primarybtn add-btn">+ Add Shopify Product Category</button></Link>
                </div>
            </div>
            
}

export default observer(ShopifyProductCategories);
